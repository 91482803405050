import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Talk from "../../components/talk";

import '../../components/index.scss';
import HomeLink from "../../components/home-link";

const GitHubActionsPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/github-actions.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = "GitHub Actions";
  const summary = `As part of an ongoing series of tech talks called "Informáticos en Casa" I shared my experiences with
  the latest product introduces by GitHub called GitHub Actions.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      recordingURL={"https://www.youtube.com/watch?v=b3rjXBumHwM&feature=youtu.be&t=83"}
      slidesURL={"https://docs.google.com/presentation/d/1WnlmXUIPkrzh-Azi_r9tw5L89UoJDxYjBt47S2waQFw/edit?usp=sharing"}
      year={2020}
      summary={summary}
    />
  </Layout>
};

export default GitHubActionsPage
